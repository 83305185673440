
@import 'styles/variables';

.navbar{
    .background-div {
        background-image: url('../../../../assets/images/dashboards/header-elipse.svg');
        background-color:  $color-bg-primary; // This will make the background transparent
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        .logo-div {
            position: relative;
            background-color: $color-bg-primary; // This will make the background transparent
        }
    }

    // Media query for mobile and below
    @media (max-width: 640px) {
        .background-div {
            background-image: url('../../../../assets/images/dashboards/header-elipse-mobile.svg'); // Change to the desired mobile image path
            background-size: cover;
            // background-position: calc(100% + 40px) center;

        }
    }

     @media (max-width: 500px) {
        .background-div {
            background-position: calc(100% + 40px) center;

        }
    }


}


.text-li {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
}

