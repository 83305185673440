$gradient-start: #005DB3; // Start color for the gradient
$gradient-end: #15A0DC; // End color for the gradient

.gradient-overlay {
  background: linear-gradient(to top, $gradient-start, $gradient-end);

  // If you need a full page or specific dimensions
//   width: 100%; // Adjust as needed
//   height: 100vh; // Adjust as needed

  // Rest of your styles
  position: relative;
  z-index: 1;

  // Content inside should be positioned above the overlay
  .content {
    position: relative;
    z-index: 2;
    // Your content styles
  }
}
