.Toastify__toast-icon--error {
    color: white !important;
    fill: white !important;
}


// .radio-single{
    .radio {
      &:checked,
      &[aria-checked="true"] {
        box-shadow:
          0 0 0 4px hsl(0, 0%, 100%) inset,
          0 0 0 4px hsl(0, 0%, 100%) inset;
        animation: none;
      }
    }
// }

.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
