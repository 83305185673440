@import '_normalize';
@import '_global';
@import '_variables';

@font-face {
    font-family: 'Poppins Regular';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('opentype');
}

html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
input,
button,
select,
textarea {
    color: #000;
    line-height: 1;
    font-weight: 400;
    font-size: 16;
    font-family: $font-family-poppins;
}

body{
    background-color: $color-bg-primary;

    .container {
        max-width: 940px;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}

html,
body{
 width: 100%;
 height: 100vh;
}

.btn{
  text-transform: none;
}

h6 {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400; /* This sets it to 'normal' weight, adjust as needed */
}

h5 {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
}

h4 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500; /* Slightly bolder than h5 and h6 */
}

h3 {
    font-size: 20px;
    line-height: 1.35;
    font-weight: 500;
}

h2 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600; /* Bold */
}

h1 {
    font-size: 28px;
    line-height: 1.45;
    font-weight: 700; /* Bold */
}

