.footerBackground {
  background-image: url("../../../../assets/images/footer-layoput.svg");
  background-size: cover;
  background-position: center 0%;
  background-repeat: no-repeat;
  position: relative;

  // Media query for mobile devices
  @media (max-width: 767px) { // 767px is a common breakpoint for mobile, adjust if needed
    background-image: linear-gradient(to bottom, #159ED9, #20428F);

    height: 491px !important;
  }
}
